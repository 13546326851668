import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import hobitLogoClaim from '../assets/HOBIT-logo-basic.png'
import { RoundedField } from '../components/RoundedField'
import { userMutations } from '../operations/mutations'
import { Role } from '../__generated__/schema'

interface FormData {
    userName: string
    password: string
    remember: boolean
}

export function SignIn() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const { loading, mutate } = userMutations.useLogin()
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()

    const onSubmit = async (formData: FormData) => {
        const result = await mutate(formData.userName, formData.password, formData.remember)
        if (result.data?.login?.success) {
            if (result.data.login.user?.roles.includes(Role.Admin)) {
                enqueueSnackbar('Byl jste přihlášen')
                history.push('/stories')
            } else enqueueSnackbar('Sekce dostupná pouze pro správce', { variant: 'error' })
        }
        if (!result.data?.login?.success) enqueueSnackbar('Špatné uživ. jméno nebo heslo', { variant: 'error' })
    }

    return (
        <>
            <Box
                component="img"
                sx={{
                    width: '289px',
                }}
                alt="Hobit logo claim"
                src={hobitLogoClaim}
            />
            <Box
                sx={{
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 30 }} color="secondary">
                    Přihlášení
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit<FormData>(onSubmit)}
                    noValidate
                    sx={{ mt: '34px', maxWidth: { xs: '93%', md: 500 } }}
                >
                    <RoundedField
                        margin="normal"
                        required
                        fullWidth
                        id="userName"
                        label="Uživatelské jméno"
                        autoComplete="userName"
                        variant="outlined"
                        autoFocus
                        {...register('userName', { required: true })}
                        error={Boolean(errors.userName)}
                        helperText={Boolean(errors.userName) ? 'Pole je povinné' : undefined}
                    />
                    <RoundedField
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        variant="outlined"
                        id="password"
                        label="Heslo"
                        autoComplete="current-password"
                        {...register('password', { required: true })}
                        error={Boolean(errors.password)}
                        helperText={Boolean(errors.password) ? 'Pole je povinné' : undefined}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={true}
                                color="default"
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<CheckCircleOutlineIcon />}
                            />
                        }
                        disableTypography
                        {...register('remember')}
                        label={
                            <Typography color="#3B3B3B" fontWeight="bold">
                                Zapamatovat tento účet
                            </Typography>
                        }
                    />
                    <Box textAlign="center" sx={{ mt: 2, mb: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ width: 290, height: 55 }}
                            disabled={loading}
                        >
                            Přihlásit se
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
