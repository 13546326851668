import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import EventEmitter from './services/eventEmitter'
import { getJwt } from './services/storage'

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getJwt()
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
        },
    }
})

const link = onError(({ graphQLErrors, networkError, response }) => {
    console.log(graphQLErrors, networkError)
    if (graphQLErrors) {
        console.log('GrapQLError', graphQLErrors)
        graphQLErrors.forEach((error) => {
            if (error.message === 'Not Authorised!')
                EventEmitter.emit('notification', { message: 'Nedostatečné oprávnění', options: { variant: 'error' } })
            else EventEmitter.emit('notification', { message: 'Nastala chyba', options: { variant: 'error' } })
        })
    }

    if (!graphQLErrors && networkError) {
        console.log(`[Network error]: ${networkError}`)
    }
})

const httpLink = createHttpLink({
    uri: '/graphql',
    useGETForQueries: true,
})

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(link).concat(httpLink),
    connectToDevTools: process.env.NODE_ENV === 'development',
})
