import { gql } from 'graphql.macro'

export const GET_USER = gql`
    query GetUser {
        user {
            _id
            userName
            age
            sex
            roles
            friends {
                _id
                userName
            }

            createdAt
        }
    }
`

export const GET_USER_BY_ID = gql`
    query GetUserById($userId: ID) {
        user(id: $userId) {
            _id
            userName
            age
            sex
            roles
            friends {
                _id
                userName
            }
            createdAt
        }
    }
`

export const GET_USERS = gql`
    query GetUsers($filter: PaginationFilter) {
        users(filter: $filter) {
            pageInfo {
                page
                perPage
                pageCount
            }
            data {
                _id
                userName
                age
                sex
                roles
                createdAt
            }
        }
    }
`
