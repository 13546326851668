import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Button, Divider, IconButton, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Box } from '@mui/system'
import { logger } from 'common/lib/logger'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { FileUpload } from '../../components/FileUpload'
import { schoolMutations, uploadMutations } from '../../operations/mutations'
import schoolMutation from '../../operations/mutations/schoolMutation'
import {
    FileType,
    useGetSchoolsLazyQuery,
    useLoadSchoolsFromFileMutation,
    useUploadFileMutation,
} from '../../__generated__/schema'

interface FormData {
    file: { filename: string; data: string }
}

export function SchoolsUpload() {
    const history = useHistory()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>()
    const [uploadFile, uploadingFile] = uploadMutations.useUploadFile(FileType.File)
    const { mutate: loadSchoolsFromFile } = schoolMutations.useUploadSchoolsFromFile()

    async function processSubmit(formData: FormData) {
        console.log('submitted', formData)

        const resultUpload = await uploadFile(formData.file.data, formData.file.filename)
        if (!resultUpload) return logger.error('unable to upload csv file')
        const [objectId] = resultUpload

        const result = await loadSchoolsFromFile(objectId)
        if (!result) logger.error('unable to load schools from file')
        history.goBack()
    }

    return (
        <Container sx={{ mt: 4, mb: 4 }} maxWidth="xl">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            // height: 600,
                        }}
                    >
                        <Box sx={{ display: 'flex', mb: 1 }}>
                            <IconButton size="small" onClick={() => history.goBack()}>
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            <Typography variant="h5" fontWeight="bold" component="span">
                                NAHRÁT SEZNAM ŠKOL
                            </Typography>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit(processSubmit)} noValidate sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} textAlign="right">
                                    <Button type="submit" disabled={uploadingFile}>
                                        Uložit
                                    </Button>
                                    <Divider />
                                </Grid>

                                <Grid item>
                                    <Box sx={{ mt: 3 }}>
                                        <Typography variant="h6" gutterBottom>
                                            Import MINDMUP
                                        </Typography>
                                        <FileUpload
                                            description="Sem přetáhněte soubor ve formátu CSV"
                                            allowedExtensions={['.csv']}
                                            {...register('file', { required: true })}
                                            error={Boolean(errors.file)}
                                            helperText={
                                                Boolean(errors.file) ? 'Přidejte soubor se seznamem škol' : undefined
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}
