import {
    GetStoriesQuery,
    useAddStoryMutation,
    useDeleteStoryMutation,
    StoryModifyInput,
    useLoadSchoolsFromFileMutation,
    GetSchoolsQuery,
} from '../../__generated__/schema'
import { GET_SCHOOLS } from '../queries/schoolQuery'
import { GET_STORIES } from '../queries/storyQuery'

export default function createSchoolMutation() {
    return {
        useUploadSchoolsFromFile: () => {
            const [mutate, { data, error, loading }] = useLoadSchoolsFromFileMutation({
                update(cache, { data }) {
                    if (data?.loadSchoolsFromFile?.success && data.loadSchoolsFromFile.data) {
                        cache.writeQuery<GetSchoolsQuery>({
                            query: GET_SCHOOLS,
                            data: {
                                schools: data?.loadSchoolsFromFile?.data,
                            },
                        })
                    }
                },
            })
            return {
                mutate: (objectId: string) => mutate({ variables: { objectId } }),
                data,
                error,
                loading,
            }
        },
    }
}
