import { gql } from 'graphql.macro'

export const GET_SCHOOLS = gql`
    query GetSchools {
        schools {
            _id
            originalId
            name
            address
        }
    }
`
