import { ApolloProvider } from '@apollo/client'
import { SnackbarProvider } from 'notistack'
import { client } from '../cache'
import { EventConnector } from './EventConnector'
import Router from './Router'
import withTheme from './withTheme'

function Root() {
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <ApolloProvider client={client}>
                <EventConnector>
                    <Router />
                </EventConnector>
            </ApolloProvider>
        </SnackbarProvider>
    )
}

export default withTheme(Root)
