import { useEffect } from 'react'
import { clearStorage, getJwt } from '../../services/storage'
import { parseJwt } from '../../utils/parseJwt'
import { History } from 'history'
import { logger } from 'common/lib/logger'

export default function createJwtMutation() {
    return {
        useExpiredToken(history: History) {
            useEffect(() => {
                const token = getJwt()
                if (token) {
                    try {
                        const { exp } = parseJwt(token)
                        const expired = exp * 1000 <= Date.now()
                        if (expired) {
                            clearStorage()
                            history.push('/')
                        } else if (history.location.pathname === '/') history.push('/stories')
                    } catch (err) {
                        logger.error('unable to parse JWT', err)
                    }
                } else history.push('/')
            }, [])
        },
    }
}
