import { logger } from 'common/lib/logger'
import { useState } from 'react'
import { FileType, useUploadFileMutation } from '../../__generated__/schema'

export default function createLogin() {
    return {
        useMindMapEditor: () => {
            const [pending, setPending] = useState(false)
            const [getUploadFileUrl] = useUploadFileMutation()
            return {
                mutate: async (imageUrl: string, previewUrl: string) => {
                    setPending(true)
                    try {
                        const resultImage = await getUploadFileUrl({ variables: { type: FileType.Image } })
                        const resultPreview = await getUploadFileUrl({ variables: { type: FileType.Image } })
                        if (!resultImage.data?.uploadFile?.success || !resultPreview.data?.uploadFile?.success) {
                            setPending(false)
                            return false
                        }

                        await fetch(resultImage.data.uploadFile.uploadUrl!, {
                            method: 'PUT',
                            body: await fetch(imageUrl).then((res) => res.blob()),
                        })

                        await fetch(resultPreview.data.uploadFile.uploadUrl!, {
                            method: 'PUT',
                            body: await fetch(previewUrl).then((res) => res.blob()),
                        })
                        return {
                            imageId: resultImage.data.uploadFile.objectId!,
                            previewId: resultPreview.data.uploadFile.objectId!,
                        }
                    } catch (err) {
                        logger.error(err)
                        setPending(false)

                        return false
                    }
                },
                loading: pending,
            }
        },
        useUploadFile: (
            type: FileType
        ): [(fileUrl: string, filename: string) => Promise<false | [string, string]>, boolean] => {
            const [pending, setPending] = useState(false)
            const [getUploadFileUrl] = useUploadFileMutation()
            return [
                async (fileUrl, filename) => {
                    setPending(true)
                    try {
                        const resultImage = await getUploadFileUrl({ variables: { type } })
                        if (!resultImage.data?.uploadFile?.success) {
                            setPending(false)
                            return false
                        }

                        const res = await fetch(resultImage.data.uploadFile.uploadUrl!, {
                            headers: new Headers({
                                'X-Amz-Meta-filename': filename,
                            }),
                            method: 'PUT',
                            body: await fetch(fileUrl).then((res) => res.blob()),
                        })
                        console.log('res', res)

                        setPending(false)
                        return [
                            resultImage.data.uploadFile.objectId! as string,
                            resultImage.data.uploadFile.objectUrl! as string,
                        ]
                    } catch (err) {
                        logger.error(err)

                        setPending(false)
                        return false
                    }
                },
                pending,
            ]
        },
    }
}
