import { Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { logger } from 'common/lib/logger'
import { values } from 'lodash'

interface ValueStr {
    data: string
    filename?: string
}
interface ValueJson {
    data: any
    filename?: string
}

interface FileUploadBase {
    error?: boolean
    helperText?: string
    name?: string
    allowedExtensions?: string[]
    description: string
}

interface FileUploadJSON extends FileUploadBase {
    onChange?: (e: any) => void
    contentType: 'json'
    onValueChange?: (value: ValueJson) => void
    value?: ValueJson
}

interface FileUploadGeneral extends FileUploadBase {
    onChange?: (e: any) => void
    onValueChange?: (value: ValueStr) => void
    value?: ValueStr
    contentType?: undefined
}

type FileUploadProps = FileUploadGeneral | FileUploadJSON

export const FileUpload = React.forwardRef<HTMLInputElement, FileUploadProps>(
    ({ error, helperText, onChange, name, allowedExtensions, onValueChange, contentType, description, value }, ref) => {
        const [errorMessage, setErrorMessage] = useState<null | string>(null)
        const [filenameState, setFilename] = useState<string>()

        useEffect(() => {
            if (!value?.filename && typeof value?.data == 'string' && value.data.startsWith('http')) {
                fetch(value.data, {
                    method: 'HEAD',
                }).then((res) => {
                    const filenameMeta = res.headers.get('x-amz-meta-filename')
                    if (filenameMeta) setFilename(filenameMeta)
                })
            }
        }, [value])

        const onChangeHandler = useCallback(
            (file: File) => {
                if (contentType !== 'json') {
                    const url = URL.createObjectURL(file)
                    const value = { data: url, name, filename: file.name }

                    if (onChange) onChange({ target: { value, name } })
                    if (onValueChange) onValueChange(value)
                    return true
                }

                const reader = new FileReader()
                return new Promise<boolean>((resolve) => {
                    reader.addEventListener('load', (event) => {
                        try {
                            const json = JSON.parse(event.target!.result as string)
                            const value = { data: json, name, filename: file.name }

                            if (onChange) onChange({ target: { value, name } })
                            if (onValueChange) onValueChange(value)
                            resolve(true)
                        } catch (err) {
                            setErrorMessage('Soubor nelze zpracovat')
                            logger.error('error during parsing', err)
                            resolve(false)
                        }
                    })
                    reader.readAsText(file)
                })
            },
            [onChange, onValueChange, name, setErrorMessage]
        )

        const onDrop = useCallback(
            async (acceptedFiles, refect, event) => {
                setErrorMessage('')
                const file = acceptedFiles[0]

                if (allowedExtensions && !allowedExtensions.some((ext) => file.name.endsWith(ext))) {
                    setErrorMessage('Podporovány jsou pouze soubory s příponou ' + allowedExtensions.join())
                    return
                }

                await onChangeHandler(file)
            },
            [onChangeHandler, setErrorMessage, allowedExtensions]
        )

        const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop })
        const filename = value?.filename ? value.filename : filenameState ? filenameState : 'Importovat'

        return (
            <>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Paper
                            component="div"
                            {...getRootProps()}
                            elevation={0}
                            sx={{
                                width: 373,
                                height: 121,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                backgroundColor: '#F7F7F7',
                            }}
                        >
                            <input ref={ref} name={name} {...getInputProps()} />
                            <Box>
                                {isDragActive ? (
                                    <p>Pusťe soubor zde...</p>
                                ) : (
                                    <>
                                        <Typography>{description}</Typography>
                                        <Typography textAlign="center">
                                            nebo <b>Procházet soubory</b>
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }} color="secondary">
                            {filename}
                        </Typography>
                    </Grid>
                </Grid>
                {errorMessage ? <Typography color="error"> {errorMessage}</Typography> : null}
                {helperText ? <Typography color={error ? 'error' : 'inherit'}> {helperText}</Typography> : null}
            </>
        )
    }
)
