import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import eventEmitter, { EmitterEvents } from '../services/eventEmitter'

export function EventConnector({ children }: { children: JSX.Element }) {
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        function handler({ message, options }: EmitterEvents['notification']) {
            enqueueSnackbar(message, options)
        }
        eventEmitter.on('notification', handler)

        return () => eventEmitter.off('notification', handler)
    }, [enqueueSnackbar])

    return children
}
