import * as React from 'react'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import { Loader } from './Loader'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

interface HeaderCell<T> {
    label: string
    key: T
    convertor?: (value: any) => string
    numeric: boolean
}

// const headCells: readonly HeadCell[] = [
//     {
//         id: 'name',
//         numeric: false,
//         disablePadding: true,
//         label: 'Dessert (100g serving)',
//     },
//     {
//         id: 'calories',
//         numeric: true,
//         disablePadding: false,
//         label: 'Calories',
//     },
//     {
//         id: 'fat',
//         numeric: true,
//         disablePadding: false,
//         label: 'Fat (g)',
//     },
//     {
//         id: 'carbs',
//         numeric: true,
//         disablePadding: false,
//         label: 'Carbs (g)',
//     },
//     {
//         id: 'protein',
//         numeric: true,
//         disablePadding: false,
//         label: 'Protein (g)',
//     },
// ]

interface EnhancedTableProps<DataKey extends string> {
    onRequestSort: (event: React.MouseEvent<unknown>, property: DataKey) => void
    order: Order
    orderBy: string
    rowCount: number
    header: HeaderCell<DataKey>[]
    actions: any
}

function EnhancedTableHead<DataKey extends string>(props: EnhancedTableProps<DataKey>) {
    const { order, orderBy, rowCount, onRequestSort, header, actions } = props
    const createSortHandler = (property: DataKey) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {header.map((headCell, i) => (
                    <TableCell
                        key={headCell.key}
                        align={headCell.numeric ? 'right' : 'left'}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.key ? order : false}
                        sx={i === 0 ? { paddingLeft: 0 } : {}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.key}
                            direction={orderBy === headCell.key ? order : 'asc'}
                            onClick={createSortHandler(headCell.key)}
                        >
                            {headCell.label}
                            {orderBy === headCell.key ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {actions ? <TableCell align="left" sx={{ width: 0 }}></TableCell> : null}
            </TableRow>
        </TableHead>
    )
}

interface EnhancedTableToolbarProps {
    title: string
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { title } = props

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                {title}
            </Typography>
        </Toolbar>
    )
}

interface EnhancedTableProps2<T> {
    header: HeaderCell<Exclude<keyof T, '_id'>>[]
    rows?: T[]
    actions?: (row: T, idx: number) => JSX.Element | JSX.Element[] | null
    defaultOrderBy?: string
    title?: string
    isLoading: boolean
}

export default function EnhancedTable<DataKey extends string>(
    props: EnhancedTableProps2<{ [key in DataKey]: string | number } & { _id: string }>
) {
    const { rows = [], header, defaultOrderBy = '_id', title, isLoading, actions } = props
    const [order, setOrder] = React.useState<Order>('asc')
    const [orderBy, setOrderBy] = React.useState<string>(defaultOrderBy)
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: DataKey) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

    return (
        // <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
            {title ? <EnhancedTableToolbar title={title} /> : null}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        header={header}
                        actions={actions}
                    />
                    <TableBody>
                        {rows
                            .slice()
                            .sort(getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const cells = header.map(({ key, convertor }, i) => (
                                    <TableCell sx={i === 0 ? { paddingLeft: 0 } : {}}>
                                        {convertor ? convertor(row[key]) : row[key]}
                                    </TableCell>
                                ))
                                if (actions) cells.push(<TableCell align="right">{actions(row, index)}</TableCell>)

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                        {cells}
                                    </TableRow>
                                )
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {isLoading ? <Loader /> : null}
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        // <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
        // </Box>
    )
}
