"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertMupToMindMap = void 0;
const ramda_1 = require("ramda");
function convertIdeas(ideas, links) {
    return (0, ramda_1.toPairs)(ideas).map(([key, node]) => ({
        id: String(node.id),
        title: node.title,
        price: Number(node.attr?.note?.text || 0),
        links: links.filter((link) => link.ideaIdFrom === node.id).map((link) => link.ideaIdTo),
        children: node.ideas ? convertIdeas(node.ideas, links) : [],
    }));
}
function convertMupToMindMap(mup) {
    const { id, ideas, links } = mup;
    return { id: 'root', formatVersion: '2', children: convertIdeas(ideas, links) };
}
exports.convertMupToMindMap = convertMupToMindMap;
