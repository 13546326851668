"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logger = exports.LogLevel = void 0;
const fn_1 = require("./fn");
const isNode = typeof window === 'undefined';
const loggerCSS = (0, fn_1.logger)(!isNode);
const consoleLog = loggerCSS(console);
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["ERROR"] = 0] = "ERROR";
    LogLevel[LogLevel["WARNING"] = 1] = "WARNING";
    LogLevel[LogLevel["INFO"] = 2] = "INFO";
    LogLevel[LogLevel["DEV"] = 3] = "DEV";
    LogLevel[LogLevel["DEBUG"] = 4] = "DEBUG";
    LogLevel[LogLevel["SILLY"] = 5] = "SILLY";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
exports.logger = {
    info: consoleLog('green', 'INFO   ', LogLevel.INFO),
    warning: consoleLog('orange', 'WARNING', LogLevel.WARNING),
    error: consoleLog('red', 'ERROR  ', LogLevel.ERROR),
    debug: consoleLog('blue', 'DEBUG  ', LogLevel.DEBUG),
    silly: consoleLog('yellow', 'SILLY  ', LogLevel.SILLY),
};
