import createUser from './userMutation'
import createStory from './storyMutation'
import createUpload from './uploadMutations'
import createSchool from './schoolMutation'
import createJwtMutation from './jwtMutation'

export const userMutations = createUser()
export const storyMutations = createStory()
export const uploadMutations = createUpload()
export const schoolMutations = createSchool()
export const jwtMutations = createJwtMutation()
