import { gql } from 'graphql.macro';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: number;
  MindMap: any;
};

export type Achievement = {
  __typename?: 'Achievement';
  _id?: Maybe<Scalars['ID']>;
  achievedScores: Array<Scalars['Float']>;
  score: Scalars['Float'];
  steps: Array<AchievementStep>;
  story: Story;
};

export type AchievementStep = {
  __typename?: 'AchievementStep';
  createdAt: Scalars['DateTime'];
  nodeId: Scalars['ID'];
  price: Scalars['Float'];
};

export type CreateUserResponse = MutationResponse & {
  __typename?: 'CreateUserResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type DownloadMutationResponse = MutationResponse & {
  __typename?: 'DownloadMutationResponse';
  message?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['String']>;
  objectUrl?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum FileType {
  Animation = 'ANIMATION',
  File = 'FILE',
  Image = 'IMAGE'
}

export type Friend = {
  __typename?: 'Friend';
  _id: Scalars['String'];
  achievements: Array<Achievement>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  friends: Array<Friend>;
  school?: Maybe<School>;
  userName: Scalars['String'];
};

export type GeneralMutationResponse = MutationResponse & {
  __typename?: 'GeneralMutationResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type LoadSchoolsFromFileResponse = MutationResponse & {
  __typename?: 'LoadSchoolsFromFileResponse';
  data?: Maybe<Array<School>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ModifyStoryResponse = MutationResponse & {
  __typename?: 'ModifyStoryResponse';
  message?: Maybe<Scalars['String']>;
  story?: Maybe<Story>;
  success: Scalars['Boolean'];
};

export type ModifyUserResponse = MutationResponse & {
  __typename?: 'ModifyUserResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAchievementStep?: Maybe<GeneralMutationResponse>;
  addFriend?: Maybe<GeneralMutationResponse>;
  addFriendOneWay?: Maybe<GeneralMutationResponse>;
  addFriendshipRequest?: Maybe<GeneralMutationResponse>;
  addPersonalExperience?: Maybe<GeneralMutationResponse>;
  addStory?: Maybe<ModifyStoryResponse>;
  addUser?: Maybe<CreateUserResponse>;
  deleteStory?: Maybe<GeneralMutationResponse>;
  deleteUser?: Maybe<GeneralMutationResponse>;
  forgot?: Maybe<GeneralMutationResponse>;
  forgotExec?: Maybe<GeneralMutationResponse>;
  generateAnalyticExport?: Maybe<DownloadMutationResponse>;
  loadSchoolsFromFile?: Maybe<LoadSchoolsFromFileResponse>;
  login?: Maybe<UserLoginResponse>;
  loginOauth?: Maybe<UserLoginResponse>;
  modifyPassword?: Maybe<GeneralMutationResponse>;
  modifyStory?: Maybe<ModifyStoryResponse>;
  modifyUser?: Maybe<ModifyUserResponse>;
  releaseStory?: Maybe<ModifyStoryResponse>;
  removeFriend?: Maybe<GeneralMutationResponse>;
  removeFriendshipRequest?: Maybe<GeneralMutationResponse>;
  replaceStory?: Maybe<ModifyStoryResponse>;
  /** Reset achievement steps, add achieved score to achievedScores */
  resetAchievement?: Maybe<GeneralMutationResponse>;
  uploadFile?: Maybe<UploadMutationResponse>;
};


export type MutationAddAchievementStepArgs = {
  nodeId: Scalars['ID'];
  price: Scalars['Float'];
  storyId: Scalars['ID'];
};


export type MutationAddFriendArgs = {
  id: Scalars['ID'];
};


export type MutationAddFriendOneWayArgs = {
  id: Scalars['ID'];
};


export type MutationAddFriendshipRequestArgs = {
  id: Scalars['ID'];
};


export type MutationAddPersonalExperienceArgs = {
  input: PersonalExperienceModifyInput;
};


export type MutationAddStoryArgs = {
  input: StoryModifyInput;
};


export type MutationAddUserArgs = {
  input: UserCreateInput;
};


export type MutationDeleteStoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationForgotArgs = {
  email: Scalars['String'];
  type: TokenType;
};


export type MutationForgotExecArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationGenerateAnalyticExportArgs = {
  limitNumberOfStoryPasses: Scalars['Int'];
};


export type MutationLoadSchoolsFromFileArgs = {
  objectId: Scalars['String'];
};


export type MutationLoginArgs = {
  deviceId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  userName: Scalars['String'];
};


export type MutationLoginOauthArgs = {
  code: Scalars['String'];
  provider: OAuthProvider;
};


export type MutationModifyPasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationModifyStoryArgs = {
  id: Scalars['ID'];
  input: StoryModifyInput;
};


export type MutationModifyUserArgs = {
  id: Scalars['ID'];
  input: UserModifyInput;
};


export type MutationReleaseStoryArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveFriendArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveFriendshipRequestArgs = {
  id: Scalars['ID'];
};


export type MutationReplaceStoryArgs = {
  id: Scalars['ID'];
  input: StoryModifyInput;
};


export type MutationResetAchievementArgs = {
  id: Scalars['ID'];
};


export type MutationUploadFileArgs = {
  fileName?: Maybe<Scalars['String']>;
  type: FileType;
};

export type MutationResponse = {
  success: Scalars['Boolean'];
};

export enum OAuthProvider {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type PaginationFilter = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type PersonalExperience = {
  __typename?: 'PersonalExperience';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
};

export type PersonalExperienceModifyInput = {
  description: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  schools: Array<School>;
  stories: Array<Story>;
  story?: Maybe<Story>;
  user?: Maybe<User>;
  users?: Maybe<UsersResponse>;
};


export type QueryStoryArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUsersArgs = {
  filter?: Maybe<PaginationFilter>;
  ids?: Maybe<Array<Scalars['ID']>>;
  searchText?: Maybe<Scalars['String']>;
};

export enum Role {
  Admin = 'ADMIN'
}

export type School = {
  __typename?: 'School';
  _id: Scalars['ID'];
  address: Scalars['String'];
  name: Scalars['String'];
  originalId: Scalars['ID'];
};

export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type Story = {
  __typename?: 'Story';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  imageId: Scalars['String'];
  imageUrl: Scalars['String'];
  mindMap: Scalars['MindMap'];
  name: Scalars['String'];
  original?: Maybe<Scalars['ID']>;
  previewId: Scalars['String'];
  previewUrl: Scalars['String'];
  rank: Scalars['Int'];
  shortName: Scalars['String'];
  status: StoryStatus;
  version: Scalars['Int'];
};

export type StoryModifyInput = {
  description: Scalars['String'];
  imageId: Scalars['String'];
  mindMap: Scalars['MindMap'];
  name: Scalars['String'];
  previewId: Scalars['String'];
  rank: Scalars['Int'];
  shortName: Scalars['String'];
};

export enum StoryStatus {
  Archived = 'ARCHIVED',
  Created = 'CREATED',
  Released = 'RELEASED'
}

export enum TokenType {
  ForgotPassword = 'FORGOT_PASSWORD'
}

export type UploadMutationResponse = MutationResponse & {
  __typename?: 'UploadMutationResponse';
  message?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['String']>;
  objectUrl?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  uploadUrl?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  achievements: Array<Achievement>;
  age?: Maybe<Scalars['Int']>;
  avatarId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  friends: Array<Friend>;
  personalExperiences?: Maybe<Array<Maybe<PersonalExperience>>>;
  roles: Array<Role>;
  school?: Maybe<School>;
  sex?: Maybe<Sex>;
  userName: Scalars['String'];
};

export type UserCreateInput = {
  age?: Maybe<Scalars['Int']>;
  avatarId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  school?: Maybe<Scalars['ID']>;
  sex?: Maybe<Sex>;
  userName: Scalars['String'];
};

export type UserLoginResponse = MutationResponse & {
  __typename?: 'UserLoginResponse';
  jwt?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type UserModifyInput = {
  age?: Maybe<Scalars['Int']>;
  avatarId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['ID']>;
  sex?: Maybe<Sex>;
  userName: Scalars['String'];
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  data: Array<User>;
  pageInfo?: Maybe<PageInfo>;
};

export type GenerateExportMutationVariables = Exact<{
  limitNumberOfStoryPasses: Scalars['Int'];
}>;


export type GenerateExportMutation = { __typename?: 'Mutation', generateAnalyticExport?: { __typename?: 'DownloadMutationResponse', success: boolean, message?: string | null | undefined, objectId?: string | null | undefined, objectUrl?: string | null | undefined } | null | undefined };

export type LoadSchoolsFromFileMutationVariables = Exact<{
  objectId: Scalars['String'];
}>;


export type LoadSchoolsFromFileMutation = { __typename?: 'Mutation', loadSchoolsFromFile?: { __typename?: 'LoadSchoolsFromFileResponse', success: boolean, message?: string | null | undefined, data?: Array<{ __typename?: 'School', _id: string, originalId: string, name: string, address: string }> | null | undefined } | null | undefined };

export type AddStoryMutationVariables = Exact<{
  input: StoryModifyInput;
}>;


export type AddStoryMutation = { __typename?: 'Mutation', addStory?: { __typename?: 'ModifyStoryResponse', success: boolean, message?: string | null | undefined, story?: { __typename?: 'Story', _id: string, name: string, shortName: string, version: number, status: StoryStatus, original?: string | null | undefined, createdAt: number, rank: number } | null | undefined } | null | undefined };

export type ReplaceStoryMutationVariables = Exact<{
  replaceStoryId: Scalars['ID'];
  input: StoryModifyInput;
}>;


export type ReplaceStoryMutation = { __typename?: 'Mutation', replaceStory?: { __typename?: 'ModifyStoryResponse', success: boolean, message?: string | null | undefined } | null | undefined };

export type ModifyStoryMutationVariables = Exact<{
  modifyStoryId: Scalars['ID'];
  input: StoryModifyInput;
}>;


export type ModifyStoryMutation = { __typename?: 'Mutation', modifyStory?: { __typename?: 'ModifyStoryResponse', success: boolean, message?: string | null | undefined, story?: { __typename?: 'Story', _id: string, name: string, shortName: string, description: string, imageUrl: string, previewUrl: string, mindMap: any, status: StoryStatus, rank: number } | null | undefined } | null | undefined };

export type DeleteStoryMutationVariables = Exact<{
  deleteStoryId: Scalars['ID'];
}>;


export type DeleteStoryMutation = { __typename?: 'Mutation', deleteStory?: { __typename?: 'GeneralMutationResponse', success: boolean, message?: string | null | undefined } | null | undefined };

export type ReleaseStoryMutationVariables = Exact<{
  storyId: Scalars['ID'];
}>;


export type ReleaseStoryMutation = { __typename?: 'Mutation', releaseStory?: { __typename?: 'ModifyStoryResponse', success: boolean, message?: string | null | undefined, story?: { __typename?: 'Story', _id: string, name: string, version: number, shortName: string, description: string, imageUrl: string, previewUrl: string, mindMap: any, status: StoryStatus, createdAt: number, rank: number } | null | undefined } | null | undefined };

export type UploadFileMutationVariables = Exact<{
  type: FileType;
  fileName?: Maybe<Scalars['String']>;
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile?: { __typename?: 'UploadMutationResponse', success: boolean, message?: string | null | undefined, uploadUrl?: string | null | undefined, objectId?: string | null | undefined, objectUrl?: string | null | undefined } | null | undefined };

export type UserLoginInfoFragment = { __typename?: 'User', _id: string, userName: string, age?: number | null | undefined, sex?: Sex | null | undefined, email?: string | null | undefined, roles: Array<Role>, createdAt: number, friends: Array<{ __typename?: 'Friend', _id: string, userName: string }> };

export type LoginMutationVariables = Exact<{
  userName: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'UserLoginResponse', success: boolean, message?: string | null | undefined, jwt?: string | null | undefined, user?: { __typename?: 'User', _id: string, userName: string, age?: number | null | undefined, sex?: Sex | null | undefined, email?: string | null | undefined, roles: Array<Role>, createdAt: number, friends: Array<{ __typename?: 'Friend', _id: string, userName: string }> } | null | undefined } | null | undefined };

export type DeleteUserMutationVariables = Exact<{
  deleteUserId: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: { __typename?: 'GeneralMutationResponse', success: boolean, message?: string | null | undefined } | null | undefined };

export type GetSchoolsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSchoolsQuery = { __typename?: 'Query', schools: Array<{ __typename?: 'School', _id: string, originalId: string, name: string, address: string }> };

export type GetStoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoriesQuery = { __typename?: 'Query', stories: Array<{ __typename?: 'Story', _id: string, name: string, version: number, status: StoryStatus, rank: number, createdAt: number }> };

export type GetStoryQueryVariables = Exact<{
  storyId: Scalars['ID'];
}>;


export type GetStoryQuery = { __typename?: 'Query', story?: { __typename?: 'Story', _id: string, name: string, shortName: string, description: string, mindMap: any, version: number, status: StoryStatus, original?: string | null | undefined, createdAt: number, imageUrl: string, imageId: string, previewUrl: string, previewId: string, rank: number } | null | undefined };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', _id: string, userName: string, age?: number | null | undefined, sex?: Sex | null | undefined, roles: Array<Role>, createdAt: number, friends: Array<{ __typename?: 'Friend', _id: string, userName: string }> } | null | undefined };

export type GetUserByIdQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;


export type GetUserByIdQuery = { __typename?: 'Query', user?: { __typename?: 'User', _id: string, userName: string, age?: number | null | undefined, sex?: Sex | null | undefined, roles: Array<Role>, createdAt: number, friends: Array<{ __typename?: 'Friend', _id: string, userName: string }> } | null | undefined };

export type GetUsersQueryVariables = Exact<{
  filter?: Maybe<PaginationFilter>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users?: { __typename?: 'UsersResponse', pageInfo?: { __typename?: 'PageInfo', page: number, perPage: number, pageCount: number } | null | undefined, data: Array<{ __typename?: 'User', _id: string, userName: string, age?: number | null | undefined, sex?: Sex | null | undefined, roles: Array<Role>, createdAt: number }> } | null | undefined };

export const UserLoginInfoFragmentDoc = gql`
    fragment UserLoginInfo on User {
  _id
  userName
  age
  sex
  email
  roles
  friends {
    _id
    userName
  }
  createdAt
}
    `;
export const GenerateExportDocument = gql`
    mutation generateExport($limitNumberOfStoryPasses: Int!) {
  generateAnalyticExport(limitNumberOfStoryPasses: $limitNumberOfStoryPasses) {
    success
    message
    objectId
    objectUrl
  }
}
    `;
export type GenerateExportMutationFn = Apollo.MutationFunction<GenerateExportMutation, GenerateExportMutationVariables>;

/**
 * __useGenerateExportMutation__
 *
 * To run a mutation, you first call `useGenerateExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateExportMutation, { data, loading, error }] = useGenerateExportMutation({
 *   variables: {
 *      limitNumberOfStoryPasses: // value for 'limitNumberOfStoryPasses'
 *   },
 * });
 */
export function useGenerateExportMutation(baseOptions?: Apollo.MutationHookOptions<GenerateExportMutation, GenerateExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateExportMutation, GenerateExportMutationVariables>(GenerateExportDocument, options);
      }
export type GenerateExportMutationHookResult = ReturnType<typeof useGenerateExportMutation>;
export type GenerateExportMutationResult = Apollo.MutationResult<GenerateExportMutation>;
export type GenerateExportMutationOptions = Apollo.BaseMutationOptions<GenerateExportMutation, GenerateExportMutationVariables>;
export const LoadSchoolsFromFileDocument = gql`
    mutation LoadSchoolsFromFile($objectId: String!) {
  loadSchoolsFromFile(objectId: $objectId) {
    success
    message
    data {
      _id
      originalId
      name
      address
    }
  }
}
    `;
export type LoadSchoolsFromFileMutationFn = Apollo.MutationFunction<LoadSchoolsFromFileMutation, LoadSchoolsFromFileMutationVariables>;

/**
 * __useLoadSchoolsFromFileMutation__
 *
 * To run a mutation, you first call `useLoadSchoolsFromFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadSchoolsFromFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadSchoolsFromFileMutation, { data, loading, error }] = useLoadSchoolsFromFileMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useLoadSchoolsFromFileMutation(baseOptions?: Apollo.MutationHookOptions<LoadSchoolsFromFileMutation, LoadSchoolsFromFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoadSchoolsFromFileMutation, LoadSchoolsFromFileMutationVariables>(LoadSchoolsFromFileDocument, options);
      }
export type LoadSchoolsFromFileMutationHookResult = ReturnType<typeof useLoadSchoolsFromFileMutation>;
export type LoadSchoolsFromFileMutationResult = Apollo.MutationResult<LoadSchoolsFromFileMutation>;
export type LoadSchoolsFromFileMutationOptions = Apollo.BaseMutationOptions<LoadSchoolsFromFileMutation, LoadSchoolsFromFileMutationVariables>;
export const AddStoryDocument = gql`
    mutation AddStory($input: StoryModifyInput!) {
  addStory(input: $input) {
    success
    message
    story {
      _id
      name
      shortName
      version
      status
      original
      createdAt
      status
      rank
    }
  }
}
    `;
export type AddStoryMutationFn = Apollo.MutationFunction<AddStoryMutation, AddStoryMutationVariables>;

/**
 * __useAddStoryMutation__
 *
 * To run a mutation, you first call `useAddStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoryMutation, { data, loading, error }] = useAddStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoryMutation(baseOptions?: Apollo.MutationHookOptions<AddStoryMutation, AddStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoryMutation, AddStoryMutationVariables>(AddStoryDocument, options);
      }
export type AddStoryMutationHookResult = ReturnType<typeof useAddStoryMutation>;
export type AddStoryMutationResult = Apollo.MutationResult<AddStoryMutation>;
export type AddStoryMutationOptions = Apollo.BaseMutationOptions<AddStoryMutation, AddStoryMutationVariables>;
export const ReplaceStoryDocument = gql`
    mutation ReplaceStory($replaceStoryId: ID!, $input: StoryModifyInput!) {
  replaceStory(id: $replaceStoryId, input: $input) {
    success
    message
  }
}
    `;
export type ReplaceStoryMutationFn = Apollo.MutationFunction<ReplaceStoryMutation, ReplaceStoryMutationVariables>;

/**
 * __useReplaceStoryMutation__
 *
 * To run a mutation, you first call `useReplaceStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceStoryMutation, { data, loading, error }] = useReplaceStoryMutation({
 *   variables: {
 *      replaceStoryId: // value for 'replaceStoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceStoryMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceStoryMutation, ReplaceStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceStoryMutation, ReplaceStoryMutationVariables>(ReplaceStoryDocument, options);
      }
export type ReplaceStoryMutationHookResult = ReturnType<typeof useReplaceStoryMutation>;
export type ReplaceStoryMutationResult = Apollo.MutationResult<ReplaceStoryMutation>;
export type ReplaceStoryMutationOptions = Apollo.BaseMutationOptions<ReplaceStoryMutation, ReplaceStoryMutationVariables>;
export const ModifyStoryDocument = gql`
    mutation ModifyStory($modifyStoryId: ID!, $input: StoryModifyInput!) {
  modifyStory(id: $modifyStoryId, input: $input) {
    success
    message
    story {
      _id
      name
      shortName
      description
      imageUrl
      previewUrl
      mindMap
      status
      rank
    }
  }
}
    `;
export type ModifyStoryMutationFn = Apollo.MutationFunction<ModifyStoryMutation, ModifyStoryMutationVariables>;

/**
 * __useModifyStoryMutation__
 *
 * To run a mutation, you first call `useModifyStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyStoryMutation, { data, loading, error }] = useModifyStoryMutation({
 *   variables: {
 *      modifyStoryId: // value for 'modifyStoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyStoryMutation(baseOptions?: Apollo.MutationHookOptions<ModifyStoryMutation, ModifyStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyStoryMutation, ModifyStoryMutationVariables>(ModifyStoryDocument, options);
      }
export type ModifyStoryMutationHookResult = ReturnType<typeof useModifyStoryMutation>;
export type ModifyStoryMutationResult = Apollo.MutationResult<ModifyStoryMutation>;
export type ModifyStoryMutationOptions = Apollo.BaseMutationOptions<ModifyStoryMutation, ModifyStoryMutationVariables>;
export const DeleteStoryDocument = gql`
    mutation DeleteStory($deleteStoryId: ID!) {
  deleteStory(id: $deleteStoryId) {
    success
    message
  }
}
    `;
export type DeleteStoryMutationFn = Apollo.MutationFunction<DeleteStoryMutation, DeleteStoryMutationVariables>;

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      deleteStoryId: // value for 'deleteStoryId'
 *   },
 * });
 */
export function useDeleteStoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryMutation, DeleteStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoryMutation, DeleteStoryMutationVariables>(DeleteStoryDocument, options);
      }
export type DeleteStoryMutationHookResult = ReturnType<typeof useDeleteStoryMutation>;
export type DeleteStoryMutationResult = Apollo.MutationResult<DeleteStoryMutation>;
export type DeleteStoryMutationOptions = Apollo.BaseMutationOptions<DeleteStoryMutation, DeleteStoryMutationVariables>;
export const ReleaseStoryDocument = gql`
    mutation ReleaseStory($storyId: ID!) {
  releaseStory(id: $storyId) {
    success
    message
    story {
      _id
      name
      version
      shortName
      description
      imageUrl
      previewUrl
      mindMap
      status
      createdAt
      rank
    }
  }
}
    `;
export type ReleaseStoryMutationFn = Apollo.MutationFunction<ReleaseStoryMutation, ReleaseStoryMutationVariables>;

/**
 * __useReleaseStoryMutation__
 *
 * To run a mutation, you first call `useReleaseStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseStoryMutation, { data, loading, error }] = useReleaseStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useReleaseStoryMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseStoryMutation, ReleaseStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseStoryMutation, ReleaseStoryMutationVariables>(ReleaseStoryDocument, options);
      }
export type ReleaseStoryMutationHookResult = ReturnType<typeof useReleaseStoryMutation>;
export type ReleaseStoryMutationResult = Apollo.MutationResult<ReleaseStoryMutation>;
export type ReleaseStoryMutationOptions = Apollo.BaseMutationOptions<ReleaseStoryMutation, ReleaseStoryMutationVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($type: FileType!, $fileName: String) {
  uploadFile(type: $type, fileName: $fileName) {
    success
    message
    uploadUrl
    objectId
    objectUrl
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      type: // value for 'type'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const LoginDocument = gql`
    mutation login($userName: String!, $password: String!) {
  login(userName: $userName, password: $password) {
    success
    message
    user {
      ...UserLoginInfo
    }
    jwt
  }
}
    ${UserLoginInfoFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($deleteUserId: ID!) {
  deleteUser(id: $deleteUserId) {
    success
    message
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deleteUserId: // value for 'deleteUserId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetSchoolsDocument = gql`
    query GetSchools {
  schools {
    _id
    originalId
    name
    address
  }
}
    `;

/**
 * __useGetSchoolsQuery__
 *
 * To run a query within a React component, call `useGetSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, options);
      }
export function useGetSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, options);
        }
export type GetSchoolsQueryHookResult = ReturnType<typeof useGetSchoolsQuery>;
export type GetSchoolsLazyQueryHookResult = ReturnType<typeof useGetSchoolsLazyQuery>;
export type GetSchoolsQueryResult = Apollo.QueryResult<GetSchoolsQuery, GetSchoolsQueryVariables>;
export const GetStoriesDocument = gql`
    query GetStories {
  stories {
    _id
    name
    version
    status
    rank
    createdAt
  }
}
    `;

/**
 * __useGetStoriesQuery__
 *
 * To run a query within a React component, call `useGetStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetStoriesQuery, GetStoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoriesQuery, GetStoriesQueryVariables>(GetStoriesDocument, options);
      }
export function useGetStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoriesQuery, GetStoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoriesQuery, GetStoriesQueryVariables>(GetStoriesDocument, options);
        }
export type GetStoriesQueryHookResult = ReturnType<typeof useGetStoriesQuery>;
export type GetStoriesLazyQueryHookResult = ReturnType<typeof useGetStoriesLazyQuery>;
export type GetStoriesQueryResult = Apollo.QueryResult<GetStoriesQuery, GetStoriesQueryVariables>;
export const GetStoryDocument = gql`
    query GetStory($storyId: ID!) {
  story(id: $storyId) {
    _id
    name
    shortName
    description
    mindMap
    version
    status
    original
    createdAt
    imageUrl
    imageId
    previewUrl
    previewId
    rank
  }
}
    `;

/**
 * __useGetStoryQuery__
 *
 * To run a query within a React component, call `useGetStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useGetStoryQuery(baseOptions: Apollo.QueryHookOptions<GetStoryQuery, GetStoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoryQuery, GetStoryQueryVariables>(GetStoryDocument, options);
      }
export function useGetStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoryQuery, GetStoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoryQuery, GetStoryQueryVariables>(GetStoryDocument, options);
        }
export type GetStoryQueryHookResult = ReturnType<typeof useGetStoryQuery>;
export type GetStoryLazyQueryHookResult = ReturnType<typeof useGetStoryLazyQuery>;
export type GetStoryQueryResult = Apollo.QueryResult<GetStoryQuery, GetStoryQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  user {
    _id
    userName
    age
    sex
    roles
    friends {
      _id
      userName
    }
    createdAt
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($userId: ID) {
  user(id: $userId) {
    _id
    userName
    age
    sex
    roles
    friends {
      _id
      userName
    }
    createdAt
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($filter: PaginationFilter) {
  users(filter: $filter) {
    pageInfo {
      page
      perPage
      pageCount
    }
    data {
      _id
      userName
      age
      sex
      roles
      createdAt
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;