import { GET_USER, GET_USERS } from '../queries/userQuery'
import {
    GetUsersQuery,
    useDeleteStoryMutation,
    useDeleteUserMutation,
    useLoginMutation,
} from '../../__generated__/schema'
import { setJwt } from '../../services/storage'
import { useState } from 'react'
import { GET_500_USERS } from '../../constants'

export default function createLogin() {
    return {
        useLogin: () => {
            const [remember, setRemember] = useState(false)
            const [mutate, { data, error, loading }] = useLoginMutation({
                update(cache, { data }) {
                    if (data?.login?.success && data.login.user && data.login.jwt) {
                        const newUser = data.login.user
                        cache.writeQuery({
                            query: GET_USER,
                            data: {
                                user: newUser,
                            },
                        })
                        setJwt(data.login.jwt, remember)
                    }
                },
            })
            return {
                mutate: (userName: string, password: string, remember: boolean = false) => {
                    setRemember(remember)
                    return mutate({ variables: { userName, password } })
                },
                data,
                error,
                loading,
            }
        },
        useDeleteUser: () => {
            const [mutate, { data, error, loading }] = useDeleteUserMutation({
                update(cache, { data }, { variables }) {
                    if (data?.deleteUser?.success) {
                        const usersData = cache.readQuery<GetUsersQuery>({
                            query: GET_USERS,
                            variables: GET_500_USERS,
                        })
                        console.log(usersData, variables)
                        if (usersData && usersData.users?.data) {
                            cache.writeQuery<GetUsersQuery>({
                                query: GET_USERS,
                                data: {
                                    __typename: usersData.__typename,
                                    users: {
                                        data: usersData.users.data.filter(
                                            (user) => user._id !== variables?.deleteUserId
                                        ),
                                        pageInfo: usersData.users.pageInfo,
                                    },
                                },
                                variables: GET_500_USERS,
                            })
                        }
                    }
                },
            })
            return {
                mutate: (userId: string) => mutate({ variables: { deleteUserId: userId } }),
                data,
                error,
                loading,
            }
        },
    }
}
