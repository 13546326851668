import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { IconButton, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Box } from '@mui/system'
import { useHistory } from 'react-router'

interface StoryAddContainerProps {
    children: JSX.Element | JSX.Element[]
}
export function StoryAddContainer({ children }: StoryAddContainerProps) {
    const history = useHistory()

    return (
        <Container sx={{ mt: 4, mb: 4 }} maxWidth="xl">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={10}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            // height: 600,
                        }}
                    >
                        <Box sx={{ display: 'flex', mb: 1 }}>
                            <IconButton size="small" onClick={() => history.goBack()}>
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            <Typography variant="h5" fontWeight="bold" component="span">
                                PŘÍBĚHY
                            </Typography>
                        </Box>
                        {children}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}
