import { Button, Card, CardMedia, Divider, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Box, styled } from '@mui/system'
import { IMindMap, IMup } from 'common/lib/types'
import { convertMupToMindMap } from 'common/lib/utils/mindMapConvertor'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FileUpload } from '../../components/FileUpload'
import { GraphViewer } from '../../components/GraphViewer'
import { RoundedField } from '../../components/RoundedField'
import { WarningDialog } from '../../components/WarningDialog'
import { uploadMutations } from '../../operations/mutations'
import { FileType, Story, StoryModifyInput } from '../../__generated__/schema'

const Header = (props: React.ComponentProps<typeof Typography>) => {
    return <Typography variant="h6" gutterBottom color="secondary" fontWeight="bold" {...props} />
}

const HeaderInline = (props: React.ComponentProps<typeof Typography>) => {
    return <Typography variant="h6" gutterBottom color="secondary" fontWeight="bold" component="span" {...props} />
}

const StyledDivier = styled(Divider)((theme) => ({
    marginTop: 40,
    marginBottom: 40,
}))

interface FormData {
    storyName: string
    storyShortName: string
    storyDescription: string
    mupJson: { data: IMup; filename: string }
    imageMain: { data: string; filename: string }
    imagePreview: { data: string; filename: string }
    rank: number
}

interface StoryEditorProps {
    defaultStory?: Story
    isModify?: boolean
    onSubmit: (input: StoryModifyInput) => Promise<any>
    onModify: (input: StoryModifyInput) => Promise<any>
    onRelease?: () => any
    onDelete?: () => any
}
export function StoryEditor({ defaultStory, onSubmit, isModify, onModify, onDelete, onRelease }: StoryEditorProps) {
    const [mindMap, setMindMap] = useState<IMindMap>()
    const [isPending, setPending] = useState(false)
    const [cnt, forceRender] = useState(0)
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            storyName: defaultStory?.name,
            storyShortName: defaultStory?.shortName,
            storyDescription: defaultStory?.description,
            mupJson: undefined,
            imageMain: { data: defaultStory?.imageUrl },
            imagePreview: { data: defaultStory?.previewUrl },
            rank: defaultStory?.rank || 0,
        },
    })
    const [uploadImage, loadingImage] = uploadMutations.useUploadFile(FileType.Image)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showReleaseDialog, setShowReleaseDialog] = useState(false)
    const [showSaveDialog, setShowSaveDialog] = useState<FormData>()

    useEffect(() => {
        if (defaultStory) {
            setMindMap(defaultStory.mindMap)
        }
    }, [defaultStory])

    async function processSubmit(formData: FormData) {
        setPending(true)

        if (isModify) {
            const imageResult = formData.imageMain.filename
                ? await uploadImage(formData.imageMain.data, formData.imageMain.filename)
                : false

            const previewResult = formData.imagePreview.filename
                ? await uploadImage(formData.imagePreview.data, formData.imagePreview.filename)
                : false

            await onModify({
                name: formData.storyName,
                shortName: formData.storyShortName,
                description: formData.storyDescription,
                imageId: imageResult ? imageResult[0] : defaultStory!.imageId,
                previewId: previewResult ? previewResult[0] : defaultStory!.previewId,
                mindMap: mindMap,
                rank: formData.rank,
            })
        } else {
            const imageResult = await uploadImage(formData.imageMain.data, formData.imageMain.filename)
            const previewResult = await uploadImage(formData.imagePreview.data, formData.imagePreview.filename)

            if (imageResult && previewResult) {
                await onSubmit({
                    name: formData.storyName,
                    shortName: formData.storyShortName,
                    description: formData.storyDescription,
                    imageId: imageResult[0],
                    previewId: previewResult[0],
                    mindMap: mindMap,
                    rank: formData.rank,
                })
            }
        }

        setPending(false)
    }

    return (
        <>
            <Box component="form" onSubmit={handleSubmit<FormData>(setShowSaveDialog)} noValidate sx={{ mt: 1 }}>
                <Grid container>
                    <Grid item xs={12} md={7} lg={8}>
                        <RoundedField
                            variant="standard"
                            required
                            fullWidth
                            InputProps={{ disableUnderline: true }}
                            id="storyName"
                            placeholder="Název příběhu"
                            inputProps={{ style: { fontWeight: 'bold', color: '#A3A3A3', fontSize: '1.5rem' } }} // font size of input text
                            {...register('storyName', { required: isModify })}
                            error={Boolean(errors.storyName)}
                            helperText={Boolean(errors.storyName) ? 'Pole je povinné' : undefined}
                        />
                    </Grid>
                    <Grid
                        item
                        xs="auto"
                        sx={{
                            marginLeft: 'auto',
                        }}
                    >
                        {onDelete ? (
                            <Button
                                sx={{ fontWeight: 'normal' }}
                                color="inherit"
                                onClick={() => setShowDeleteDialog(true)}
                            >
                                Smazat
                            </Button>
                        ) : null}

                        <Button
                            color="secondary"
                            sx={{ fontWeight: 'bold' }}
                            onClick={() => setShowReleaseDialog(true)}
                            disabled={!Boolean(onRelease)}
                        >
                            Zveřejnit
                        </Button>
                        <Button color="secondary" sx={{ fontWeight: 'bold' }} type="submit" disabled={isPending}>
                            Uložit
                        </Button>
                    </Grid>
                </Grid>
                <Divider />

                <Box sx={{ mt: '40px' }}>
                    <Box>
                        <HeaderInline>Zkrácený název</HeaderInline>
                        <Typography variant="body2" component="span">
                            &ensp;(zobrazí se v mini náhledu příběhu v hlavním menu)
                        </Typography>
                    </Box>
                    <RoundedField
                        variant="outlined"
                        required
                        id="storyShortName"
                        fullWidth
                        {...register('storyShortName', { required: true })}
                        error={Boolean(errors.storyShortName)}
                        helperText={Boolean(errors.storyShortName) ? 'Pole je povinné' : undefined}
                    />
                </Box>
                <Box sx={{ mt: '40px' }}>
                    <Box>
                        <HeaderInline>Pořadí</HeaderInline>
                        <Typography variant="body2" component="span">
                            &ensp;(udává v jakém pořadí se budou příběhy zobrazovat&ensp;(sestupně)
                        </Typography>
                    </Box>
                    <RoundedField
                        variant="outlined"
                        required
                        type="number"
                        id="rank"
                        fullWidth
                        {...register('rank', { required: true, valueAsNumber: true })}
                        error={Boolean(errors.rank)}
                        helperText={Boolean(errors.rank) ? 'Pole je povinné' : undefined}
                    />
                </Box>

                <StyledDivier />
                <Box>
                    <Box>
                        <HeaderInline>Popisek příběhu</HeaderInline>
                        <Typography variant="body2" component="span">
                            &ensp;(zobrazí se v hlavním menu pod názvem příběhu)
                        </Typography>
                    </Box>
                    <RoundedField
                        variant="filled"
                        required
                        fullWidth
                        id="storyDescription"
                        InputProps={{ disableUnderline: true }}
                        multiline
                        {...register('storyDescription', { required: true })}
                        error={Boolean(errors.storyDescription)}
                        helperText={Boolean(errors.storyDescription) ? 'Pole je povinné' : undefined}
                    />
                </Box>
                <StyledDivier />
                {isModify ? null : (
                    <>
                        <Box>
                            <Header>Import MINDMUP</Header>
                            <FileUpload
                                description="Sem přetáhněte soubor ve formátu MUP"
                                allowedExtensions={['.mup']}
                                contentType="json"
                                {...register('mupJson', { required: true })}
                                value={getValues('mupJson')}
                                onValueChange={(value) => setMindMap(convertMupToMindMap(value.data))}
                                error={Boolean(errors.mupJson)}
                                helperText={Boolean(errors.mupJson) ? 'Přidejte soubor s mind mapou' : undefined}
                            />
                        </Box>
                        <StyledDivier />
                    </>
                )}

                <Box>
                    <Header>Import OBRÁZKŮ</Header>
                    <Grid container justifyContent="space-between" spacing={1}>
                        <Grid item md="auto">
                            <FileUpload
                                description="Sem přetáhněte obrázek příběhu"
                                allowedExtensions={['.jpg', '.jpeg', '.png']}
                                {...register('imageMain', { required: Boolean(!isModify) })}
                                value={getValues('imageMain')}
                                onValueChange={() => forceRender(cnt + 1)}
                                error={Boolean(errors.imageMain)}
                                helperText={Boolean(errors.imageMain) ? 'Přidejte obrázek' : undefined}
                            />
                        </Grid>
                        <Grid item md="auto">
                            <FileUpload
                                description="Sem přetáhněte náhled příběhu"
                                allowedExtensions={['.jpg', '.jpeg', '.png']}
                                {...register('imagePreview', { required: Boolean(!isModify) })}
                                value={getValues('imagePreview')}
                                onValueChange={() => forceRender(cnt + 1)}
                                error={Boolean(errors.imagePreview)}
                                helperText={Boolean(errors.imagePreview) ? 'Přidejte náhled příběhu' : undefined}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <StyledDivier />
                <Box>
                    <Header>Náhled příběhu</Header>
                    {mindMap ? <GraphViewer mindMap={mindMap} setMindMap={setMindMap} /> : null}
                </Box>
                <StyledDivier />
                <Box>
                    <Header>Náhled obrázků</Header>
                    <Grid container>
                        <Grid item>
                            {getValues('imageMain') ? (
                                <Card sx={{ maxWidth: 345 }}>
                                    <CardMedia component="img" height="345" src={getValues('imageMain').data} />
                                </Card>
                            ) : null}
                        </Grid>
                        <Grid item>
                            {getValues('imagePreview') ? (
                                <Card sx={{ maxWidth: 345 }}>
                                    <CardMedia component="img" height="345" image={getValues('imagePreview').data} />
                                </Card>
                            ) : null}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <WarningDialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                onAgree={onDelete!}
                body="Opravdu chcete smazat rozhovor?"
                agreeText="Smazat"
            />
            <WarningDialog
                open={showReleaseDialog}
                onClose={() => setShowReleaseDialog(false)}
                onAgree={onRelease!}
                body="Opravdu chcete publikovat příběh do aplikace?"
                agreeText="Publikovat"
            />
            <WarningDialog
                open={Boolean(showSaveDialog)}
                onClose={() => setShowSaveDialog(undefined)}
                onAgree={() => showSaveDialog && processSubmit(showSaveDialog)}
                body="Opravdu chcete uložit změny?"
                agreeText="uložit"
            />
        </>
    )
}
