import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useCallback, useState } from 'react'

interface WarningDialogProps {
    open: boolean
    onClose: () => any
    onAgree: () => any
    body?: string
    agreeText?: string
}
export function WarningDialog({
    open,
    onClose,
    onAgree,
    body = 'Opravdu chcete akci provést?',
    agreeText = 'Potvrdit',
}: WarningDialogProps) {
    const [pending, setPending] = useState(false)
    const handleAgree = useCallback(async () => {
        setPending(true)
        await onAgree()
        setPending(false)
    }, [onAgree])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { p: 3 } }}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle color="primary" sx={{ mb: 3, mt: 3, textAlign: 'center', fontWeight: 'bold' }}>
                {body}
            </DialogTitle>
            <DialogActions disableSpacing>
                <Button
                    onClick={onClose}
                    variant="contained"
                    size="large"
                    sx={{ width: 290, height: 55, backgroundColor: '#868686' }}
                >
                    Zrušit
                </Button>
                <Button
                    onClick={handleAgree}
                    variant="contained"
                    size="large"
                    sx={{ width: 290, height: 55, ml: 4 }}
                    disabled={pending}
                >
                    {agreeText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
