import { logger } from 'common/lib/logger'
import { IMindMap, IMindMapNode } from 'common/lib/types'
import { clone } from 'ramda'
import { useCallback, useState } from 'react'
import { uploadMutations } from '../operations/mutations'
import { FileType, useUploadFileMutation } from '../__generated__/schema'

function updateAnimationUrl(id: string, animationUrl: string, node: IMindMapNode) {
    if (node.id === id) {
        node.animationUrl = animationUrl
        return
    }

    for (const child of node.children || []) {
        if (child.id === id) {
            child.animationUrl = animationUrl
            return
        }

        updateAnimationUrl(id, animationUrl, child)
    }
}

export function useUpdateMindMap() {
    const [pending, setPending] = useState(false)
    const [uploadFile, _] = uploadMutations.useUploadFile(FileType.Animation)

    const mutate = useCallback(
        async function (nodeId: string, animationData: any, animationFilename: string, mindMap: IMindMap) {
            setPending(true)

            try {
                var animationBlob = new Blob([JSON.stringify(animationData)], {
                    type: 'application/json',
                })
                const animationUrl = URL.createObjectURL(animationBlob)
                const result = await uploadFile(animationUrl, animationFilename)
                if (!result) {
                    setPending(false)
                    return
                }
                const [objectId, objectUrl] = result

                const newMindMap = clone(mindMap)
                newMindMap.children?.forEach((node) => updateAnimationUrl(nodeId, objectUrl, node))

                setPending(false)

                return newMindMap
            } catch (err) {
                logger.error(err)

                setPending(false)
            }
        },
        [pending, setPending]
    )

    return { pending, mutate }
}
