import { clone } from 'ramda'
import React, { useMemo } from 'react'
import Lottie from 'lottie-react'

class ErrorBoundary extends React.Component {
    state: { hasError: boolean }
    constructor(props: any) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, errorInfo)
        console.log('did catch', error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1 style={{ color: 'red' }}>Soubor nelze načíst</h1>
        }

        return this.props.children
    }
}

interface LottieViewerProps {
    data?: any
}
export function LottieViewer({ data }: LottieViewerProps) {
    const clonedData = useMemo(() => clone(data), [data])

    return data ? (
        <ErrorBoundary>
            <Lottie animationData={clonedData} />
        </ErrorBoundary>
    ) : null
}
