import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useState } from 'react'
import { Loader } from '../components/Loader'
import BasicTable from '../components/Table'
import { WarningDialog } from '../components/WarningDialog'
import { GET_500_USERS } from '../constants'
import { userMutations } from '../operations/mutations'
import { Sex, useGetUsersQuery } from '../__generated__/schema'

const sexMapper: { [key in Sex]: string } = {
    [Sex.Female]: 'žena',
    [Sex.Male]: 'muž',
    [Sex.Other]: 'jiné',
}

export function Users() {
    const [idToDelete, setIdToDelete] = useState<string>()
    const { loading, data } = useGetUsersQuery({ variables: GET_500_USERS })
    const { loading: loadingDelete, mutate } = userMutations.useDeleteUser()

    async function handleDelete() {
        if (idToDelete) {
            mutate(idToDelete)
            setIdToDelete(undefined)
        }
    }

    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    {/* Chart */}
                    <Grid item xs={12} md={12} lg={10}>
                        <Paper
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 570,
                            }}
                        >
                            <BasicTable
                                title="Uživatelé"
                                hover
                                header={[
                                    { label: 'Přezdívka', key: 'userName' },
                                    { label: 'Role', key: 'roles' },
                                    // {
                                    //     label: 'Datum vytvoření',
                                    //     key: 'createdAt',
                                    //     convertor: (value: User['createdAt']) => new Date(value).toLocaleDateString(),
                                    // },
                                    { label: 'Věk', key: 'age' },
                                    {
                                        label: 'Pohlaví',
                                        key: 'sex',
                                        convertor: (val?: Sex) => (val ? sexMapper[val] : ''),
                                    },
                                ]}
                                isLoading={loading}
                                rows={data?.users?.data}
                                actions={(row, i) =>
                                    loadingDelete ? (
                                        <Loader />
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            onClick={() => setIdToDelete(row._id)}
                                            sx={{ cursor: 'pointer' }}
                                            color="secondary"
                                            fontWeight="bold"
                                        >
                                            Smazat
                                        </Typography>
                                    )
                                }
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <WarningDialog
                open={Boolean(idToDelete)}
                onClose={() => setIdToDelete(undefined)}
                onAgree={handleDelete}
                agreeText="Smazat"
            />
        </>
    )
}
