import AddIcon from '@mui/icons-material/Add'
import { Button, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Box } from '@mui/system'
import * as React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { AddCircleIcon } from '../components/AddCircleIcon'
import SortTable from '../components/SortTable'
import { Story, StoryStatus, useGetStoriesQuery } from '../__generated__/schema'

const statusMapper: { [key in StoryStatus]: string } = {
    [StoryStatus.Archived]: 'Smazáno',
    [StoryStatus.Created]: 'Koncept',
    [StoryStatus.Released]: 'Publikováno',
}

export function Stories() {
    const { loading, data } = useGetStoriesQuery({ pollInterval: 10000 })
    const history = useHistory()

    function handleRowClick(row: { _id: string }) {
        history.push(`/stories/add/${row._id}`)
    }
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={10}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="h5" fontWeight="bold" gutterBottom sx={{ textTransform: 'uppercase' }}>
                            Příběhy
                        </Typography>
                        <Box>
                            <Link to="/stories/add">
                                <Button
                                    size="large"
                                    startIcon={<AddCircleIcon />}
                                    sx={{ paddingLeft: 0, fontWeight: 'bold' }}
                                    color="secondary"
                                >
                                    Vytvořit příběh
                                </Button>
                            </Link>
                        </Box>
                        <SortTable
                            header={[
                                { label: 'Název', key: 'name', numeric: false },
                                {
                                    label: 'Stav',
                                    key: 'status',
                                    convertor: (status: StoryStatus) => statusMapper[status],
                                    numeric: false,
                                },
                                { label: 'Pořadí', key: 'rank', numeric: false },
                                {
                                    label: 'Datum vytvoření',
                                    key: 'createdAt',
                                    convertor: (value: Story['createdAt']) => new Date(value).toLocaleDateString(),
                                    numeric: false,
                                },
                            ]}
                            rows={data?.stories}
                            isLoading={loading}
                            actions={(row, i) => (
                                <Typography
                                    variant="body2"
                                    onClick={() => handleRowClick(row)}
                                    sx={{ cursor: 'pointer' }}
                                    color="secondary"
                                    fontWeight="bold"
                                >
                                    Upravit
                                </Typography>
                            )}
                            defaultOrderBy="rank"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}
