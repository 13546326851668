import BarChartIcon from '@mui/icons-material/BarChart'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import PeopleIcon from '@mui/icons-material/People'
import { ListItemButton, Typography } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled, TypographyProps } from '@mui/system'
import { Link } from 'react-router-dom'
import HouseIcon from '@mui/icons-material/House'

const ITEMS = [
    {
        path: '/stories',
        icon: ChatBubbleOutlineIcon,
        label: 'Příběhy',
    },
    {
        path: '/users',
        icon: PeopleIcon,
        label: 'Uživatelé',
    },
    // {
    //     path: '/schools',
    //     icon: <HouseIcon color="primary" />,
    //     label: 'Školy',
    // },
    {
        path: '/analytics',
        icon: BarChartIcon,
        label: 'Export dat',
    },
]

const Text = styled(Typography)<TypographyProps>(() => ({
    fontWeight: 'bold',
}))

export const mainListItems = (path: string) => (
    <div>
        {ITEMS.map((item) => {
            const Icon = item.icon
            const isSelected = path.startsWith(item.path)
            const color = isSelected ? 'primary' : 'inherit'
            const colorText = isSelected ? 'primary' : '#A3A3A3'
            return (
                <Link to={item.path} key={item.path}>
                    <ListItemButton selected={isSelected}>
                        <ListItemIcon>
                            <Icon color={color} />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Text color={colorText} sx={{ fontWeight: isSelected ? 'bold' : 400 }}>
                                    {item.label}
                                </Text>
                            }
                            disableTypography={true}
                        />
                    </ListItemButton>
                </Link>
            )
        })}
    </div>
)
