const jwtKey = 'jwt'

export function setJwt(token: string, persistant: boolean) {
    if (persistant) {
        localStorage.setItem(jwtKey, token)
    } else {
        sessionStorage.setItem(jwtKey, token)
    }
}

export function getJwt() {
    return sessionStorage.getItem(jwtKey) || localStorage.getItem(jwtKey)
}

export function clearStorage() {
    sessionStorage.clear()
    localStorage.clear()
}
