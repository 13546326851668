import { orange } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import * as React from 'react'

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string
        }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string
        }
    }
}

const theme = createTheme({
    typography: {
        fontFamily: "'Dosis', sans-serif",
    },
    status: {
        danger: orange[500],
    },
    palette: {
        primary: {
            main: '#EC004B',
        },
        secondary: {
            main: '#7A1695',
        },
        error: { main: '#EC004B' },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    fontWeight: 'bold',
                    borderRadius: 40,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 11,
                },
            },
        },
    },
})

function withTheme<P>(Component: React.FunctionComponent<P>) {
    function withTheme(props: P) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        return (
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...props} />
            </ThemeProvider>
        )
    }

    return withTheme
}

export default withTheme
