import { Box, Typography } from '@mui/material'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { styled } from '@mui/styles'
import { forwardRef, RefObject } from 'react'

const RoundedTextField = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '40px',
    },
}))

export const RoundedField = forwardRef<HTMLDivElement, TextFieldProps>(({ helperText, ...props }, ref) => {
    return (
        <RoundedTextField
            helperText={
                <Typography align="right" fontSize={16}>
                    {helperText}
                </Typography>
            }
            {...props}
            ref={ref}
        />
    )
})
