import {
    GetStoriesQuery,
    useAddStoryMutation,
    useDeleteStoryMutation,
    StoryModifyInput,
    useReleaseStoryMutation,
} from '../../__generated__/schema'
import { GET_STORIES } from '../queries/storyQuery'

export default function storyMutation() {
    return {
        useDelete: () => {
            const [mutate, { data, error, loading }] = useDeleteStoryMutation({
                update(cache, { data }, { variables }) {
                    if (data?.deleteStory?.success) {
                        const storiesData = cache.readQuery<GetStoriesQuery>({
                            query: GET_STORIES,
                        })
                        if (storiesData)
                            cache.writeQuery<GetStoriesQuery>({
                                query: GET_STORIES,
                                data: {
                                    __typename: storiesData?.__typename,
                                    stories: storiesData.stories.filter(({ _id }) => _id !== variables?.deleteStoryId),
                                },
                            })
                    }
                },
            })
            return {
                mutate: (storyId: string) => mutate({ variables: { deleteStoryId: storyId } }),
                data,
                error,
                loading,
            }
        },
        useAdd: () => {
            const [mutate, { data, error, loading }] = useAddStoryMutation({
                update(cache, { data }) {
                    if (data?.addStory?.success && data.addStory.story) {
                        const storiesData = cache.readQuery<GetStoriesQuery>({
                            query: GET_STORIES,
                        })
                        if (!storiesData) return

                        cache.writeQuery<GetStoriesQuery>({
                            query: GET_STORIES,
                            data: {
                                __typename: storiesData?.__typename,
                                stories: [...(storiesData?.stories || []), data.addStory.story],
                            },
                        })
                    }
                },
            })

            return {
                mutate: (input: StoryModifyInput) => mutate({ variables: { input: input } }),
                data,
                error,
                loading,
            }
        },
        useRelease: () => {
            const [mutate, { data, error, loading }] = useReleaseStoryMutation()

            return {
                mutate: (storyId: string) => mutate({ variables: { storyId } }),
                data,
                error,
                loading,
            }
        },
    }
}
