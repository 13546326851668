import { Button, TextField, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Box } from '@mui/system'
import FileSaver from 'file-saver'
import { useState } from 'react'
import { Loader } from '../components/Loader'
import { RoundedField } from '../components/RoundedField'
import { useGenerateExportMutation } from '../__generated__/schema'

export function Analytics() {
    const [limitStoryPass, setLimitStoryPass] = useState(10)
    const [mutate, { data, error, loading }] = useGenerateExportMutation()
    async function generateAndDownload() {
        const result = await mutate({ variables: { limitNumberOfStoryPasses: 10 } })
        const data = result.data?.generateAnalyticExport
        if (!data?.success || !data?.objectId || !data?.objectUrl) return

        const { objectUrl, objectId } = data
        FileSaver.saveAs(objectUrl, objectId)
    }
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={6}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="h5" fontWeight="bold" gutterBottom>
                            Export dat
                        </Typography>
                        <Box sx={{ mb: 1 }}>
                            <Typography variant="body2" fontWeight="bold">
                                Limit počtu průchodů jednoho příběhu
                            </Typography>
                        </Box>
                        <RoundedField
                            variant="outlined"
                            required
                            type="number"
                            id="rank"
                            // InputProps={{ disableUnderline: true }}
                            value={limitStoryPass}
                            sx={{ width: 80 }}
                            onChange={(e) =>
                                Number(e.target.value) > 0 ? setLimitStoryPass(Number(e.target.value)) : null
                            }
                        />
                        <Box sx={{ textAlign: 'center', mt: 6 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ width: 290, height: 55, textTransform: 'uppercase' }}
                                disabled={loading}
                                onClick={() => generateAndDownload()}
                            >
                                Vygenerovat a stáhnout
                            </Button>
                            <Typography>
                                Data se vygenerují ve formátu <b>.xlsx</b>
                            </Typography>
                            {loading ? <Loader /> : null}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}
