import { useCallback, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Loader } from '../components/Loader'
import { storyMutations } from '../operations/mutations'
import { StoryModifyInput, StoryStatus, useGetStoryLazyQuery, useModifyStoryMutation } from '../__generated__/schema'
import { StoryAddContainer } from './storyAdd/StoryAddContainer'
import { StoryEditor } from './storyAdd/StoryEditor'

export function StoryAdd() {
    const { mutate: addStoryMutate } = storyMutations.useAdd()
    const [updateStoryMutate] = useModifyStoryMutation()
    const [loadStory, { loading, data }] = useGetStoryLazyQuery()
    const { mutate: deleteStoryMutate } = storyMutations.useDelete()
    const { mutate: releaseMutate } = storyMutations.useRelease()
    const match = useRouteMatch<{ id?: string }>()
    const storyId = match.params.id
    const isModify = Boolean(storyId)
    const history = useHistory()

    useEffect(() => {
        if (isModify) loadStory({ variables: { storyId: storyId! } })
    }, [isModify, loadStory, storyId])

    const handler = useCallback(
        async (input: StoryModifyInput) => {
            const result = await addStoryMutate(input)
            if (result.data?.addStory?.success) history.push('/stories')
        },
        [addStoryMutate, history]
    )

    const handlerModify = useCallback(
        async (input: StoryModifyInput) => {
            const result = await updateStoryMutate({
                variables: {
                    input,
                    modifyStoryId: storyId!,
                },
            })
            if (result.data?.modifyStory?.success) history.push('/stories')
        },
        [updateStoryMutate, storyId, history]
    )

    const handleDelete = useCallback(async () => {
        const result = await deleteStoryMutate(storyId!)
        if (result.data?.deleteStory?.success) history.push('/stories')
    }, [deleteStoryMutate, storyId, history])

    const handleRelease = useCallback(async () => {
        const result = await releaseMutate(storyId!)
        if (result.data?.releaseStory?.success) history.push('/stories')
    }, [releaseMutate, storyId, history])

    return (
        <StoryAddContainer>
            {isModify && (loading || !data?.story) ? (
                <Loader />
            ) : (
                <StoryEditor
                    onDelete={storyId ? handleDelete : undefined}
                    onRelease={storyId && data?.story?.status === StoryStatus.Created ? handleRelease : undefined}
                    onSubmit={handler}
                    onModify={handlerModify}
                    isModify={isModify}
                    defaultStory={data?.story ? data?.story : undefined}
                />
            )}
        </StoryAddContainer>
    )
}
