import { Dialog, DialogContent, Divider, Paper, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { logger } from 'common/lib/logger'
import { IMindMap } from 'common/lib/types'
import { useCallback, useMemo, useState } from 'react'
import { useUpdateMindMap } from '../hooks/mindMapHook'
import { convertMindMapToFE } from '../utils/mindMapConverterFE'
import { FileUpload } from './FileUpload'
import { Loader } from './Loader'
import { LottieViewer } from './LottieViewer'
import VisGraph, { GraphClickEvent } from './VisGraph'

const StyledDivier = styled(Divider)((theme) => ({
    marginTop: 35,
    marginBottom: 35,
}))

const options = {
    layout: {
        hierarchical: {
            enabled: true,
            direction: 'LR',
            sortMethod: 'directed',
            levelSeparation: 400,
        },
    },
    edges: {
        color: '#000000',
    },
    height: '700px',
    nodes: {
        shape: 'box',
        widthConstraint: {
            maximum: 350,
            // height: 700,
        },
    },
    physics: {
        // hierarchicalRepulsion: {
        // nodeDistance: 350,
        // avoidOverlap: 1,
        // },
        // timestep: 0.3,
        // stabilization: true,
    },
    interaction: {
        dragNodes: false,
    },
}

interface GraphViewerProps {
    mindMap: IMindMap
    setMindMap?: (mindMap: IMindMap) => void
    disableImport?: boolean
}
export function GraphViewer({ mindMap, setMindMap, disableImport }: GraphViewerProps) {
    const [nodeId, setNodeId] = useState<string>()
    const { pending, mutate: uploadAnimation } = useUpdateMindMap()
    const [isLoading, setIsLoading] = useState(false)
    const [filename, setFilename] = useState<string>()

    const [defaultAnimationData, setDefaultAnimation] = useState<any>()
    const [animationData, setAnimation] = useState<any>()

    const graph = useMemo(() => convertMindMapToFE(mindMap), [mindMap])

    const setSelected = useCallback(
        (nodeId: string) => {
            const node = graph.nodes.find((node) => node.id === nodeId)
            setAnimation(undefined)
            setDefaultAnimation(undefined)

            if (node?.title) {
                setIsLoading(true)

                fetch(node.title as string)
                    .then((res) => {
                        const filenameMeta = res.headers.get('x-amz-meta-filename')
                        if (filenameMeta) setFilename(filenameMeta)
                        return res
                    })
                    .then((res) => res.json())
                    .then((json) => setDefaultAnimation(json))
                    .catch((err) => logger.error(err))
                    .finally(() => setIsLoading(false))
            }

            setNodeId(nodeId)
        },
        [setAnimation, nodeId, setIsLoading]
    )

    const events = useMemo(() => {
        return {
            doubleClick: function (event: GraphClickEvent) {
                if (event.nodes.length) {
                    const node = event.nodes[0]
                    setSelected(node)
                }
            },
        }
    }, [setSelected])

    async function closeDialog() {
        if (pending) return
        if (!animationData || !setMindMap || !nodeId) return setNodeId(undefined)

        const newMindMap = await uploadAnimation(nodeId, animationData, filename!, mindMap)

        if (newMindMap) setMindMap(newMindMap)
        setNodeId(undefined)
    }

    // console.log('default', defaultAnimationData && (defaultAnimationData as any).layers[7].ks.p.k[1])
    // console.log('new', animationData && (animationData as any).layers[7].ks.p.k[1])

    return (
        <Paper>
            <VisGraph graph={graph} options={options} events={events} />
            <Dialog onClose={closeDialog} open={Boolean(nodeId)}>
                <DialogContent>
                    {pending ? (
                        <Loader />
                    ) : (
                        <>
                            {!disableImport ? (
                                <>
                                    <Box>
                                        <Typography variant="h6" gutterBottom>
                                            Import ANIMACE
                                        </Typography>
                                        <FileUpload
                                            description="Sem přetáhněte animaci ve formátu json"
                                            allowedExtensions={['.json']}
                                            contentType="json"
                                            value={{ data: animationData || defaultAnimationData, filename: filename }}
                                            onValueChange={(value) => {
                                                setAnimation(value.data)
                                                setFilename(value.filename)
                                            }}
                                        />
                                    </Box>
                                    <StyledDivier />
                                </>
                            ) : null}
                            <Box>
                                <Typography variant="h6" gutterBottom>
                                    Náhled animace
                                </Typography>
                                {isLoading ? <Loader /> : <LottieViewer data={animationData || defaultAnimationData} />}
                            </Box>{' '}
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </Paper>
    )
}
