import { IMindMap, IMindMapNode } from 'common/lib/types'
import { Edge, Node } from 'vis-network'
import { GraphData } from '../components/VisGraph'

function convertChildrenToNodes(parentId: string, children: IMindMapNode[] | undefined, nodes: Node[], edges: Edge[]) {
    if (!children) return

    children.forEach((child) => {
        nodes.push({
            id: child.id,
            label: `[${child.price}] ${child.title}`,
            color: child.animationUrl ? 'red' : 'orange',
            title: child.animationUrl,
        })

        edges.push({
            from: parentId,
            to: child.id,
        })

        child.links?.forEach((linkTo) => {
            edges.push({
                from: child.id,
                to: linkTo,
            })
        })

        convertChildrenToNodes(child.id, child.children, nodes, edges)
    })
}

export function convertMindMapToFE(mindMap: IMindMap): GraphData {
    let nodes: Node[] = []
    let edges: Edge[] = []

    nodes.push({
        id: mindMap.id,
        label: 'root',
        value: 0,
        color: 'yellow',
    })

    convertChildrenToNodes(mindMap.id, mindMap.children, nodes, edges)

    return {
        nodes,
        edges,
    }
}
