import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

export function Loader() {
    return (
        <Box
            sx={{
                width: 50,
                height: 50,
                margin: '10px auto 0px auto',
            }}
        >
            <CircularProgress />
        </Box>
    )
}
