import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { Loader } from './Loader'

type Row = {
    _id: string
    [key: string]: any
}
interface BasicTableProps {
    title?: string
    hover?: boolean
    header: { label: string; key: string; convertor?: (value: any) => string }[]
    rows?: Row[]
    isLoading: boolean
    onRowClick?: (row: Row) => void
    actions?: (row: Row, idx: number) => JSX.Element | JSX.Element[] | null
}

function createRow(
    header: BasicTableProps['header'],
    onClick?: (row: Row) => void,
    hover?: boolean,
    actions?: BasicTableProps['actions']
) {
    return (row: Row, i: number) => (
        <TableRow
            hover={hover}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={onClick && (() => onClick(row))}
            key={row._id}
        >
            {header.map(({ key, convertor }, i) => (
                <TableCell align="left" key={key} sx={i === 0 ? { paddingLeft: 0 } : {}}>
                    {convertor ? convertor(row[key]) : row[key]}
                </TableCell>
            ))}
            {actions ? <TableCell align="right">{actions(row, i)}</TableCell> : null}
        </TableRow>
    )
}

export default function BasicTable({ title, header, rows, isLoading, onRowClick, actions, hover }: BasicTableProps) {
    return (
        <>
            {title ? (
                <Typography
                    variant="h5"
                    id="tableTitle"
                    component="div"
                    sx={{ textTransform: 'uppercase' }}
                    fontWeight="bold"
                >
                    {title}
                </Typography>
            ) : null}
            <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {header.map(({ label, key }, i) => (
                                <TableCell key={key} align="left" sx={i === 0 ? { paddingLeft: 0 } : {}}>
                                    {label}
                                </TableCell>
                            ))}
                            {actions ? <TableCell align="left" sx={{ width: 0 }}></TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows ? rows.map(createRow(header, onRowClick, hover, actions)) : null}</TableBody>
                </Table>
                {isLoading ? <Loader /> : null}
            </TableContainer>
        </>
    )
}
