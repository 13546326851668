import { createBrowserHistory } from 'history'
import React, { Suspense } from 'react'
import { Router } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { jwtMutations } from '../operations/mutations'
import { Analytics } from '../pages/Analytics'
import { SchoolsUpload } from '../pages/schools/SchoolsUpload'
import { SignIn } from '../pages/SignIn'
import { Stories } from '../pages/Stories'
import { StoryAdd } from '../pages/StoryAdd'
import { Users } from '../pages/Users'
import { Layout } from './Layout'

const history = createBrowserHistory()

function RouterComponent() {
    jwtMutations.useExpiredToken(history)

    return (
        <Suspense fallback={<span>loading... [some nice loading]</span>}>
            <Router history={history}>
                <Switch>
                    <Route path="/" exact>
                        <SignIn />
                    </Route>
                    <Route path="/">
                        <Layout>
                            <Route path="/stories/add/:id?">
                                <StoryAdd />
                            </Route>
                            <Route path="/stories" exact>
                                <Stories />
                            </Route>
                            <Route path="/users" exact>
                                <Users />
                            </Route>
                            <Route path="/schools/upload" exact>
                                <SchoolsUpload />
                            </Route>
                            {/* <Route path="/schools" exact>
                                <Schools />
                            </Route> */}

                            <Route path="/analytics" exact>
                                <Analytics />
                            </Route>
                        </Layout>
                    </Route>
                </Switch>
            </Router>
        </Suspense>
    )
}

export default RouterComponent
