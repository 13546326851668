import { gql } from 'graphql.macro'

export const GET_STORIES = gql`
    query GetStories {
        stories {
            _id
            name
            version
            status
            rank
            createdAt
        }
    }
`

export const GET_STORY = gql`
    query GetStory($storyId: ID!) {
        story(id: $storyId) {
            _id
            name
            shortName
            description
            mindMap
            version
            status
            original
            createdAt
            imageUrl
            imageId
            previewUrl
            previewId
            rank
        }
    }
`
